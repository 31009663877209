<template>
  <div>loading</div>
</template>
<script>
export default {
  created() {
    this.$router.replace('/getsteamlibrary')
  }
}

</script>
<style scoped>

</style>